<template>
    <div class="mobile-page-layout">
        <div class="news-title-row">
            <div class="news-title-back-btn">
                <div class="mobile-page-header-icon pointer back-icon" v-on:click="back()">
                    <i class="header-uil-icon uil uil-angle-right-b"></i>
                </div>
            </div>
            <div class="news-title-content">
                <h2 class="market-news-content-title" v-if="this.content" v-html="this.content.title"></h2>
                <div class="market-news-content-date" v-if="this.content" v-html="this.content.jalali_created_at"></div>
            </div>
        </div>
        <img class="market-news-content-image" v-if="this.content" :src="this.content.image">
        <div class="market-news-content-body" v-if="this.content" v-html="this.content.body.replace(/(<\/?(?:span|img|b|strong|br|h1|h2|h3|h4|h5|h6|ul|li|ol|table|thead|tbody|tr|th|td|div)[^>]*>)|<[^>]+>/ig, '$1')"></div>
    </div> 
</template>

<script>

export default {
    name: 'ApplicationNewsContent',
    components: {
    },
    props: ['content'],
    data: function () {
        return {
        }
    },
    mounted() {
    },
    methods: {
        // دکمه بازگشت
        back() {
            this.$router.go(-1);
        },
    }
}
</script>
